import { render, staticRenderFns } from "./setupReservation.vue?vue&type=template&id=0bd69cee&scoped=true"
import script from "./setupReservation.vue?vue&type=script&lang=js"
export * from "./setupReservation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd69cee",
  null
  
)

export default component.exports